exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-en-us-tsx": () => import("./../../../src/pages/index.en-US.tsx" /* webpackChunkName: "component---src-pages-index-en-us-tsx" */),
  "component---src-pages-index-zh-tsx": () => import("./../../../src/pages/index.zh.tsx" /* webpackChunkName: "component---src-pages-index-zh-tsx" */),
  "component---src-pages-portfolio-en-us-tsx": () => import("./../../../src/pages/portfolio.en-US.tsx" /* webpackChunkName: "component---src-pages-portfolio-en-us-tsx" */),
  "component---src-pages-writing-en-us-tsx": () => import("./../../../src/pages/writing.en-US.tsx" /* webpackChunkName: "component---src-pages-writing-en-us-tsx" */),
  "component---src-pages-writing-zh-tsx": () => import("./../../../src/pages/writing.zh.tsx" /* webpackChunkName: "component---src-pages-writing-zh-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

